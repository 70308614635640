<template>
  <div class="body">
    <div class="body_main" :style="{ height: move + 420 + 'px' }">
      <div class="body_main_box">
        <div class="body_main_box_title">{{ ulList1.title }}</div>
        <ul>
          <li
            v-for="(item, index) in ulList1.li"
            :key="index"
            @click="link(item.name)"
          >
            <span>
              {{ item.title }}
            </span>
          </li>
        </ul>
      </div>
      <div class="body_main_box">
        <div class="body_main_box_title">{{ ulList2.title }}</div>
        <ul>
          <li
            v-for="(item, index) in ulList2.li"
            :key="index"
            @click="linkProducts(item.id, item.name)"
          >
            <span>
              {{ item.name }}
            </span>
          </li>
        </ul>
      </div>
      <div class="body_main_box">
        <div class="body_main_box_title">{{ ulList3.title }}</div>
        <ul>
          <li
            v-for="(item, index) in ulList3.li"
            :key="index"
            @click="link(item.name)"
          >
            <span>
              {{ item.title }}
            </span>
          </li>
        </ul>
      </div>
      <!-- logo -->
      <div class="logo" v-if="ulList3.title == 'SUPPORT & SERVICE'">
        <img src="@/assets/index/logoX.png" alt="" />
        <div>
          <a
            :href="'http://' + item.link"
            v-for="(item, index) in partner"
            :key="index"
            target="_blank"
            ><img :src="item.icon" alt=""
          /></a>
        </div>
      </div>
      <!-- 联系地址 -->
      <div class="address">
        <div v-for="(item, index) of address" :key="index">{{ item }}</div>
      </div>
    </div>
    <div class="body_bottom">
      Orchid Technologies co.,Ltd&emsp;Address: 1801,Jinmei Building, NO. 88
      Changqing Rd ,Jinniu District, Chengdu,China.
    </div>
  </div>
</template>

<script>
import api from "@/utils/api";

export default {
  data() {
    return {
      ulList1: {
        title: "COMPANY",
        li: [
          { title: "Company Overview", name: "contactus" },
          { title: "Company address", name: "companyAddress" },
          { title: "become a partner", name: "becomeApartner" },
        ],
      },
      ulList2: {
        title: "PRODUCTS",
        li: [
          "Fixed Retail Scanners",
          "Handheld Scanners",
          "Mobile Computers",
          "Sensors",
          "Laser Marking Systems",
          "Safety",
          "Machine Vision",
          "Stationary Industrial Scanners",
          "RFID Systems",
          "OEM Barcode Readers",
        ],
      },
      ulList3: {
        title: "SUPPORT & SERVICE",
        li: [
          { title: "Downloads Overview", name: "downloads" },
          { title: "Services", name: "service" },
          { title: "Technical Support", name: "techniCalSupport" },
          { title: "Product Support", name: "product" },
        ],
      },
      partner: [], //合作伙伴列表
      address: [], //联系地址
      move: 0, //高度偏移
    };
  },
  mounted() {
    this.partnerList();
    this.productTypeList();
    this.getCompanyInfo();
  },
  methods: {
    //获取合作伙伴
    partnerList() {
      api.partnerList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.partner = data.data;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    //跳转方法
    link(name) {
      this.$router.push({ name });
    },
    //中间一栏的跳转
    linkProducts(id, name) {
      sessionStorage.setItem("proTypeListName", name);
      sessionStorage.setItem("proTwoTypeListName", "");
      this.$router.push({ path: "/retalt", query: { id } });
    },
    //获取产品分类
    productTypeList() {
      api.productTypeList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.ulList2.li = data.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getCompanyInfo() {
      api.getCompanyInfo().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.address = data.data;
          setTimeout(() => {
            let addressDom = document.getElementsByClassName("address")[0];
            if (addressDom.scrollHeight > 85) {
              this.move = addressDom.scrollHeight - 85;
            }
          }, 1);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  min-width: 1432px;
  background: #004a9c;
  // outline: 1px solid red;
}
.body_main {
  height: 442px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 1156px;
  position: relative;
  .body_main_box {
    padding-top: 60px;
    text-align: center;

    .body_main_box_title {
      font-size: 18px;
      font-family: Arial;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 40px;
      text-align: left;
    }
    ul {
      list-style: none;
      text-align: left;
      padding: 0;
      li {
        height: 14px;
        font-size: 14px;
        font-family: Arial;
        font-weight: 400;
        color: #fffffd;
        margin-bottom: 14px;
        cursor: pointer;
        padding-bottom: 5px;
      }
      li:hover span {
        border-bottom: 1px solid #fff;
      }
      li span {
        padding-bottom: 3px;
      }
    }
  }
  .logo {
    height: 85px;
    position: absolute;
    left: 0;
    // top: 300px;
    bottom: 75px;
    text-align: left;
    > img {
      width: 150px;
      height: 45px;
      margin-bottom: 20px;
    }
    div {
      display: flex;
      justify-content: space-between;
      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #0d57a9;
      }
      a:not(:nth-of-type(1)) {
        margin-left: 10px;
      }
    }
  }

  .address {
    width: 600px;
    // height: 85px;
    position: absolute;
    left: 50%;
    // top: 300px;
    bottom: 45px;
    transform: translate(-40%, 0);
    clear: both;
    text-align: left;
    // outline: 1px solid red;
    > div {
      color: #fff;
      font-size: 16px;
      line-height: 26px;
    }
    // text-align: left;
  }
}
.body_bottom {
  min-width: 1156px;
  text-align: center;
  height: 40px;
  background: #0d57a9;
  line-height: 40px;
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #ffffff;
}
</style>
