
<template>
  <div class="body">
    <div class="body_box">
      <div class="body_top">
        <img src="@/assets/index/logoB.png" alt="" />
        <div>
          <div>
            <img src="@/assets/index/global.png" alt="" />
            <span>Global | English</span>
          </div>
          <div>ENG | CHANGE COUNTRY</div>
        </div>
      </div>
      <div class="body_bottom">
        <div class="body_bottom_ul">
          <div
            class="body_bottom_ul_show"
            @click="home"
            @mouseenter="showCheck(1)"
            @mouseleave="hiddenCheck(1)"
            :class="showCheckFlag ? 'showCheck' : ''"
          >
            PRODUCTS
          </div>
          <div
            @click="industries"
            @mouseenter="showCheck(2)"
            @mouseleave="hiddenCheck(2)"
            :class="showCheckFlag2 ? 'showCheck' : ''"
          >
            INDUSTRIES & APPLICATIONS
          </div>
          <div
            @click="support"
            @mouseenter="showCheck(3)"
            @mouseleave="hiddenCheck(3)"
            :class="showCheckFlag3 ? 'showCheck' : ''"
          >
            SUPPORT & SERVICE
          </div>
          <div
            @click="contactus"
            @mouseenter="showCheck(4)"
            @mouseleave="hiddenCheck(4)"
            :class="showCheckFlag4 ? 'showCheck' : ''"
          >
            CONTACT US
          </div>
        </div>
        <div class="body_bottom_login"></div>
      </div>
      <!-- products的下拉框 -->
      <div
        class="checkBox"
        v-show="showCheckFlag"
        @mouseenter="showCheck(1)"
        @mouseleave="hiddenCheck(1)"
      >
        <div class="checkBox_body">
          <div
            class="checkBox_ul"
            v-for="(item, index) in showCheckUl"
            :key="index"
          >
            <div class="checkBox_ul_title" @click="path(item.name, item.id)">
              {{ item.name }}
            </div>
            <div
              class="checkBox_ul_li"
              v-for="(item2, index2) in item.twoTypeList"
              :key="index2"
              @click="path1_2(item.name, item.id, item2.id, item2.name)"
            >
				<span>
					{{ item2.name }}
				</span>
            </div>
            <div
              class="slicer"
              v-if="index != 0"
              :style="{ height: 45 + item.twoTypeList.length * 26 - 10 + 'px' }"
            >
              <!-- 白条 -->
            </div>
          </div>
        </div>
      </div>
      <!-- industries的下拉框 -->
      <div
        class="checkBox"
        v-show="showCheckFlag2"
        @mouseenter="showCheck(2)"
        @mouseleave="hiddenCheck(2)"
      >
        <div class="checkBox_body">
          <div
            class="checkBox_ul"
            v-for="(item, index) in showCheckUl2"
            :key="index"
          >
            <div class="checkBox_ul_title" @click="path2(item.name, item.id)">
              {{ item.name }}
            </div>
            <div
              class="checkBox_ul_li"
              v-for="(item2, index2) in item.twoTypeList"
              :key="index2"
              @click="path2_2(item.name, item2.id)"
            >
              <span>
              	{{ item2.name }}
              </span>
            </div>
            <div
              class="slicer"
              v-if="index != 0"
              :style="{ height: 45 + item.twoTypeList.length * 26 - 10 + 'px' }"
            >
              <!-- 白条 -->
            </div>
          </div>
        </div>
      </div>
      <!-- contactUs的下拉框 -->
      <div
        class="checkBox checkBox4"
        v-show="showCheckFlag4"
        @mouseenter="showCheck(4)"
        @mouseleave="hiddenCheck(4)"
      >
        <div class="checkBox_body">
          <div
            class="checkBox_ul"
            v-for="(item, index) in showCheckUl4"
            :key="index"
          >
            <div class="checkBox_ul_title" @click="path4(item.title, index)">
              {{ item.title }}
            </div>
            <div
              class="checkBox_ul_li"
              v-for="(item2, index2) in item.li"
              :key="index2"
            >
			<span>
				{{ item2 }}
			</span>
            </div>
            <div class="slicer" v-if="index != 0" style="height: 35px">
              <!-- 白条 -->
            </div>
          </div>
        </div>
      </div>
      <!-- support的下拉框 -->
      <div
        class="checkBox checkBox4"
        v-show="showCheckFlag3"
        @mouseenter="showCheck(3)"
        @mouseleave="hiddenCheck(3)"
      >
        <div class="checkBox_body">
          <div
            class="checkBox_ul"
            v-for="(item, index) in showCheckUl3"
            :key="index"
          >
            <div class="checkBox_ul_title" @click="path3(item.title, index)">
              {{ item.title }}
            </div>
            <div
              class="checkBox_ul_li"
              v-for="(item2, index2) in item.li"
              :key="index2"
            >
              <span>
              	{{ item2 }}
              </span>
            </div>
            <div class="slicer" v-if="index != 0" style="height: 35px">
              <!-- 白条 -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api";
export default {
  data() {
    return {
      showCheckFlag: false,
      showCheckUl: [],
      showCheckFlag2: false,
      showCheckUl2: [],
      showCheckFlag3: false,
      showCheckUl3: [
        {
          title: "DOWNLOADS",
        },
        {
          title: "SERVICES",
        },
        {
          title: "TECHNICAL SUPPORT",
        },
        {
          title: "PRODUCT CERTIFICATION",
        },
      ],
      showCheckFlag4: false,
      showCheckUl4: [
        {
          title: "COMPANY OVERVIEW",
        },
        {
          title: "CONTACT US",
        },
        {
          title: "BECOME A PARTNER",
        },
      ],
    };
  },
  mounted() {
    this.applicationTypeList();
    this.productTypeList();
  },
  methods: {
    showCheck(num) {
      if (num == 1) {
        this.showCheckFlag = true;
      } else if (num == 2) {
        this.showCheckFlag2 = true;
      } else if (num == 4) {
        this.showCheckFlag4 = true;
      } else {
        this.showCheckFlag3 = true;
      }
    },
    hiddenCheck(num) {
      if (num == 1) {
        this.showCheckFlag = false;
      } else if (num == 2) {
        this.showCheckFlag2 = false;
      } else if (num == 4) {
        this.showCheckFlag4 = false;
      } else {
        this.showCheckFlag3 = false;
      }
    },
    //产品(products)菜单的跳转
    path(e, id) {
      console.log(e);
      // return false;
      sessionStorage.setItem("productSearch", id);
      sessionStorage.setItem("proTypeListName", e);
      this.$router.push({ path: "/retalt", query: { id } });
      sessionStorage.removeItem("proTwoTypeListName");
      this.hiddenCheck(1);
    },
    //industries菜单的跳转
    path2(e, id) {
      this.$router.push({ path: "/appRetail", query: { id } });
      this.hiddenCheck(2);
    },
    //产品的二级菜单的跳转
    path1_2(e, pid, id, name) {
      sessionStorage.setItem("productSearch", pid);
      sessionStorage.setItem("proTypeListName", e);
      sessionStorage.setItem("proTwoTypeListName", name);
      this.$router.push({ path: "/retalt", query: { id } });
      this.hiddenCheck(1);
    },
    //industries二级菜单的跳转
    path2_2(e, id) {
      let twoTypeListId = "";
      let twoTypeListName = "";
      this.showCheckUl2.forEach((item) => {
        item.twoTypeList.forEach((item2) => {
          if (item2.id == id) {
            twoTypeListId = item.id;
            twoTypeListName = item.name;
            return false;
          }
        });
      });
      sessionStorage.setItem("oneTypeListId", twoTypeListId);
      sessionStorage.setItem("oneTypeListName", twoTypeListName);
      this.$router.push({ path: "/automated", query: { id } });
      this.hiddenCheck(2);
    },
    //support菜单的跳转
    path3(e, index) {
		  sessionStorage.setItem("oneCountListName", e);
      if (index == 2) {
        this.$router.push({ path: "techniCalSupport" });
      } else if (index == 1) {
        this.$router.push({ path: "service" });
      } else if (index == 0) {
        this.$router.push({ name: "downloads" });
      } else {
        this.$router.push({ name: "product" });
      }
      this.hiddenCheck(3);
    },
    //contactus菜单的跳转
    path4(e, index) {
	  sessionStorage.setItem("oneCountListName", e);
      if (index == 2) {
        this.$router.push({ path: "becomeApartner" });
      } else if (index == 1) {
        this.$router.push({ path: "companyAddress" });
      } else if (index == 0) {
        this.$router.push({ name: "contactus" });
      }
      this.hiddenCheck(4);
    },
    //行业与应用的跳转
    industries() {
      this.$router.push({ name: "industries" });
    },
    //联系我们的跳转
    contactus() {
      this.$router.push({ name: "contactus" });
    },
    //
    support() {
      this.$router.push({ name: "support" });
    },
    //首页
    home() {
      this.$router.push({ name: "index" });
    },
    //获取行业与应用分类
    applicationTypeList() {
      api.applicationTypeList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.showCheckUl2 = data.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取产品分类
    productTypeList() {
      api.productTypeList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.showCheckUl = data.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  min-width: 1256px;
  width: 100%;
  height: 100px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  background: #ffffff;
  top: 0;
  z-index: 100;
  .body_box {
    margin: 0 auto;
    width: 1152px;
    padding-top: 17px;
    height: 83px;

    .body_top {
      display: flex;
      justify-content: space-between;
      height: 45px;
      margin-bottom: 17px;
      > img {
        height: 163px;
        height: 50px;
      }
      > div {
        height: 38px;
        font-size: 14px;
        width: 195px;
        font-family: Arial;
        font-weight: 400;
        margin-right: 3px;
        > div:nth-of-type(1) {
          display: flex;
          align-content: center;
          justify-content: flex-end;
          color: #777777;
          height: 14px;
          margin-bottom: 10px;
          img {
            height: 20px;
            width: 20px;
            margin-right: 10px;
          }
          span {
            height: 20px;
            line-height: 20px;
            letter-spacing: 2px;
          }
        }
        > div:nth-of-type(2) {
          height: 14px;
          display: flex;
          justify-content: flex-end;
          color: #004a9c;
        }
      }
    }
    .body_bottom {
      height: 18px;
      display: flex;
      justify-content: space-between;
      .body_bottom_ul {
        display: flex;
        width: 764px;
        // justify-content: space-between;
        div {
          cursor: pointer;
          height: 20px;
          font-size: 16px;
          line-height: 20px;
          font-family: Arial;
          font-weight: bold;
          color: #5a5b5e;
          // outline: 1px solid red;
          padding: 0 20px;
        }
        .showCheck {
          background: #004a9c;
          color: #ffffff;
        }
      }
      .body_bottom_login {
        height: 18px;
        line-height: 18px;
        font-size: 14px;
        font-family: Arial;
        font-weight: 400;
        color: #5a5b5e;
      }
    }
    .checkBox {
      width: 100vw;
      height: 335px;
      background: #004a9c;
      position: absolute;
      left: 0;
      z-index: 10;
      .checkBox_body {
        width: 1156px;
        margin: 0 auto;
        background: #004a9c;
        z-index: 10;
        position: relative;
        display: flex;
        justify-content: space-around;
        .checkBox_ul {
          position: relative;
          .checkBox_ul_title {
            margin-left: 28px;
            text-align: left;
            cursor: pointer;
            font-size: 16px;
            font-family: Arial;
            font-weight: bold;
            // text-decoration: underline;
            color: #ffffff;
            margin-top: 32px;
            margin-bottom: 28px;
			display: inline-block;
			padding-bottom: 3px;
			border-bottom: 1px solid #fff;
          }
          .checkBox_ul_li {
            cursor: pointer;
            margin-left: 28px;
            font-size: 14px;
            font-family: Arial;
            font-weight: 400;
            color: #fffffd;
            line-height: 26px;
          }
          .checkBox_ul_li:hover span{
            border-bottom: 1px solid #fff;
          }
		  .checkBox_ul_li span{
			  padding-bottom: 3px;
		  }
          .slicer {
            width: 1px;
            background: #ffffff;
            height: 10px;
            position: absolute;
            top: 32px;
            left: 0;
          }
        }
      }
    }
    .checkBox4 {
      height: 100px;
    }
  }
}
</style>

