import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index/index";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/retalt",
    name: "retalt",
    component: () => import(/* webpackChunkName: "retalt" */ "../views/index/retalt")
  }, {
    path: "/retaltInfo",
    name: "retaltInfo",
    component: () => import(/* webpackChunkName: "retaltInfo" */ "../views/index/retaltInfo"),
    props:true
  }, {
    path: "/successStories",
    name: "successStories",
    component: () => import(/* webpackChunkName: "successStories" */ "../views/index/successStories")
  }, {
    path: "/successInfo",
    name: "successInfo",
    component: () => import(/* webpackChunkName: "successInfo" */ "../views/index/successInfo")
  },
  {
    path: "/industries",
    name: "industries",
    component: () => import(/* webpackChunkName: "industries" */ "../views/industries/index")
  }, {
    path: "/appRetail",
    name: "appRetail",
    component: () => import(/* webpackChunkName: "retail" */ "../views/industries/retail")
  }, {
    path: "/automated",
    name: "automated",
    component: () => import(/* webpackChunkName: "automated" */ "../views/industries/automated")
  }, {
    path: "/contactus",
    name: "contactus",
    component: () => import(/* webpackChunkName: "contactus" */ "../views/contactus/index")
  }, {
    path: "/becomeApartner",
    name: "becomeApartner",
    component: () => import(/* webpackChunkName: "becomeApartner" */ "../views/contactus/becomeApartner")
  }, {
    path: "/companyAddress",
    name: "companyAddress",
    component: () => import(/* webpackChunkName: "companyAddress" */ "../views/contactus/companyAddress")
  }, {
    path: "/support",
    name: "support",
    component: () => import(/* webpackChunkName: "support" */ "../views/support/index")
  }, {
    path: "/downloads",
    name: "downloads",
    component: () => import(/* webpackChunkName: "downloads" */ "../views/support/downloads")
  }, {
    path: "/service",
    name: "service",
    component: () => import(/* webpackChunkName: "service" */ "../views/support/service")
  },
  {
    path: "/techniCalSupport",
    name: "techniCalSupport",
    component: () => import(/* webpackChunkName: "techniCalSupport" */ "../views/support/contactUs")
  },
  {
    path: "/product",
    name: "product",
    component: () => import(/* webpackChunkName: "product" */ "../views/support/product")
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  
});

router.beforeEach((to, from, next)=>{

  document.documentElement.scrollTop = 0;
  next();
});



export default router;
