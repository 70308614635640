<template>
  <div
    class="upTop"
    @click="upTop()"
    @mouseover="mouseOver()"
    @mouseleave="mouseLeave()"
  >
    <!-- <div class="img"> -->
    <img :src="upImg" alt="" />
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      upImg: require("@/assets/index/up.png"),
      upImg1: require("@/assets/index/up.png"),
      upImg2: require("@/assets/index/up1.png"),
    };
  },
  methods: {
    upTop() {
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop;
      //获得当前高度
      let step = distance / 50;
      //每步的距离
      (function jump() {
        if (distance > 0) {
          distance -= step;
          // document.documentElement.scrollTop = distance;
          // document.body.scrollTop = distance;
          window.scrollTo(0, distance);
          setTimeout(jump, 10);
        }
      })();
    },
    mouseOver() {
      this.upImg = this.upImg2;
    },
    mouseLeave() {
      this.upImg = this.upImg1;
    },
  },
};
</script>
<style scoped lang="scss">
.upTop {
  position: fixed;
  left: 10px;
  top: 80%;
  z-index: 999;
  img {
    width: 50px;
    height: 50px;
    // background-color: #004a9c;
  }
}
</style>