import { get, post } from './http.js'

const mode = {
    // 轮播图
    bannerList() {
        return get('/api/home/bannerList')
    },
    // 公司地址
    companyAddress() {
        return get('/api/home/companyAddress')
    },
    // 公司简介
    companyProfile() {
        return get('/api/home/companyProfile')
    },
    // 首页公司简介
    homeCompanyIntroductionList() {
        return get('/api/home/homeCompanyIntroductionList')
    },
    // 合作伙伴
    partnerList() {
        return get('/api/home/partnerList')
    },
    // 产品认证-下载-搜索
    productCertificationDownloadSearch(data) {
        return get('/api/home/productCertificationDownloadSearch', data)
    },
    // 产品名称-下拉框
    productNameList(data) {
        return get('/api/home/productNameList', data)
    },
    //成功案例-推荐
    successStoriesRecommendList() {
        return get('/api/home/successStoriesRecommendList')
    },
    //成功案例-更多
    successStoriesList(data) {
        return get('/api/home/successStoriesList', data)
    },
    //成功案列-详情
    successStoriesInfo(data) {
        return get('/api/home/successStoriesInfo', data)
    },
    //申请试用-确认
    saveTrialApplication(data) {
        return post('/api/home/saveTrialApplication', data)
    },
    //页面顶部-行业与应用分类
    applicationTypeList() {
        return get('/api/home/applicationTypeList')
    },
    //行业与应用一级分类
    applicationOneTypeList() {
        return get('/api/home/applicationOneTypeList')
    },
    //行业与应用一级分类-详情
    applicationOneTypeInfo(data) {
        return get('/api/home/applicationOneTypeInfo', data)
    },
    //行业与应用二级分类-详情
    applicationTwoTypeInfo(data) {
        return get('/api/home/applicationTwoTypeInfo', data)
    },
    //支持服务-下载-搜索
    supportServiceDownloadSearch(data) {
        return get('/api/home/supportServiceDownloadSearch', data)
    },
    //技术支持-确认
    saveTechnicalSupport(data) {
        return post('/api/home/saveTechnicalSupport', data)
    },
    //页面顶部-产品分类
    productTypeList() {
        return get('/api/home/productTypeList')
    },
    //成为合作伙伴-确认
    savePartnerApply(data) {
        return post('/api/home/savePartnerApply', data)
    },
    //产品一级分类
    productOneTypeList() {
        return get('/api/home/productOneTypeList')
    },
    //产品二级分类
    productTwoTypeList(data) {
        return get('/api/home/productTwoTypeList', data)
    },
    //产品-详情
    productInfo(data) {
        return get('/api/home/productInfo', data)
    },
    //产品-模具与配件
    productMouldList(data) {
        return get('/api/home/productMouldList', data)
    },
    //下载
    productDownloadList(data) {
        return get('/api/home/productDownloadList', data)
    },
    //服务
    supportServiceInfo() {
        return get('/api/home/supportServiceInfo')
    },
    //产品-搜索
    productSearch(data) {
        return get('/api/home/productSearch', data)
    },
    //产品-概述
    productOverviewsList(data) {
        return get('/api/home/productOverviewsList', data)
    },
    //页面底部-公司信息
    getCompanyInfo() {
        return get('/api/home/companyInfo')
    },
    //获取全部商品列表
    getProductAllList() {
        return get('/api/home/productAllList')
    }

}

export default mode;
////////
//


// //登录

// export function login(data) {
//     return post('/app/login', data)
// }

// //发送验证码
// export function sendMobileCode(data) {
//     return get('/app/sendMobileCode', data)
// }






