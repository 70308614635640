<template>
  <div id="app">
    <HeaderBar />
    <UpTop />
    <div class="body_hight"></div>
    <router-view class="routerView" />
    <BootomBar />
  </div>
</template>

<script>
import HeaderBar from "@/components/headerBar/headerBar";
import BootomBar from "@/components/bottomBar/bottomBar";
import UpTop from "@/components/upTop/upTop";
export default {
  name: "Home",
  components: {
    HeaderBar,
    BootomBar,
    UpTop,
  },
};
</script>

<style lang="scss">
.body_hight {
  height: 100px;
  width: 100%;
}
* {
  padding: 0;
  margin: 0;
}

.routerView {
  min-width: 1432px;
}
</style>
