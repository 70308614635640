<template>
  <!-- 首页 -->
  <div class="body">
    <!-- 轮播图 -->
    <div class="carousel">
      <el-carousel
        :interval="5000"
        arrow="never"
        :autoplay="autoplayFlag"
        @change="f_change"
      >
        <el-carousel-item v-for="(item, index) in carouselList" :key="index">
          <img v-if="item.type === 'img'" :src="item.url" alt="" />
          <div class="videoStyle" v-if="item.type === 'video'">
            <video
              class="video"
              :src="item.url"
              muted
              controlslist="nodownload noremoteplayback noremoteplayback"
              controls
              height="500"
              @ended="autoplayFlag = true"
              @play="autoplayFlag = false"
              @pause="autoplayFlag = true"
            />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 产品product -->
    <div class="product">
      <div class="product_title">PRODUCT</div>
      <div class="product_mainBox">
        <div
          class="product_leftButton"
          @mousedown="move(0)"
          @mouseup="clearMove()"
          @mouseleave="clearMove()"
        >
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="product_main">
          <div
            id="productMain"
            :style="{
              width: productList.length * 237 + 'px',
              left: moveNum + 'px',
            }"
          >
            <div
              class="product_main_box"
              v-for="item in productList"
              :key="item.id"
              @click="toProduct(item.id)"
            >
              <img :src="item.mainUrl" alt="" />
              <div>{{ item.name }}</div>
              <span>
                <!-- {{item.description}} -->
              </span>
            </div>
          </div>
        </div>
        <div
          class="product_rightButton"
          @mousedown="move(1)"
          @mouseup="clearMove()"
          @mouseleave="clearMove()"
        >
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <!-- 关于 对话 About DataLogic -->
    <div class="about">
      <div class="about_box" v-for="(item, index) in aboutList" :key="index">
        <div
          :class="item.url ? 'about_box_img ' : 'about_box_img about_box_img2'"
        >
          <img :src="item.url" alt="" />
        </div>
        <div
          :class="
            item.url ? 'about_box_title ' : 'about_box_title about_box_title2'
          "
        >
          <div>{{ item.title }}</div>
          <div>
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api";
// import testImg from "@/assets/logo1.png";
// import testVideo from "@/assets/test.mp4";

export default {
  data() {
    return {
      carouselList: [], //轮播图
      aboutList: [], //公司简介
      productList: [], //产品列表
      moveNum: 0, //移动位置
      timeOutEvent: null,
      autoplayFlag: true, //轮播图是否自动播放
    };
  },
  mounted() {
    this.getBannerList();
    this.homeCompanyIntroductionList();
    this.productTwoTypeList();
  },
  methods: {
    //获取轮播图
    getBannerList() {
      api.bannerList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.carouselList = [];
          data.data.forEach((item) => {
            let type = item.url.split(".");
            if (
              type[type.length - 1] === "jpg" ||
              type[type.length - 1] === "png"
            ) {
              this.carouselList.push({
                url: item.url,
                type: "img",
              });
            } else {
              this.carouselList.push({
                url: item.url,
                type: "video",
              });
            }
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    //获取首页的公司简介
    homeCompanyIntroductionList() {
      api.homeCompanyIntroductionList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.aboutList = data.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //产品一级分类
    // productOneTypeList() {
    //   api.productOneTypeList().then((res) => {
    //     let data = res.data;
    //     if (data.code == 200) {
    //       this.productList = data.data;
    //     } else {
    //       this.$message.error(data.msg);
    //     }
    //   });
    // },
    //二级页面的跳转
    // retalt(name,id) {
    //   sessionStorage.setItem("proTypeListName", name);
    //   sessionStorage.setItem("productOneTypeId", id);
    //   this.$router.push({ name: "retalt", query: { id } });
    // },
    toProduct(id) {
      document.documentElement.scrollTop = 0;
      this.$router.push({
        path: "/retaltInfo",
        query: { id },
      });
    },

    productTwoTypeList() {
      api.getProductAllList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.productList = data.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    move(numberFlag) {
      let Main = document.getElementById("productMain");
      let move = this.moveNum;
      if (numberFlag === 1) {
        this.timeOutEvent = setInterval(() => {
          move -= 4;
          if (parseInt(Main.style.width) - 1156 < -move) {
            this.moveNum = -parseInt(Main.style.width) + 1156;
            clearInterval(this.timeOutEvent);
          } else {
            this.moveNum -= 4;
          }
        }, 1);
      } else {
        this.timeOutEvent = setInterval(() => {
          move += 4;
          if (0 < move) {
            this.moveNum = 0;
          } else {
            this.moveNum += 4;
          }
        }, 1);
      }
    },
    //移除事件,清除clearInterval
    clearMove() {
      clearInterval(this.timeOutEvent);
    },
    //切换轮播图暂停轮播图
    f_change() {
      let videoList = document.getElementsByClassName("video");
      for (let i = 0; i < videoList.length; i++) {
        videoList[i].pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>


.carousel {
  height: 500px;

  .videoStyle {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    background: black;

    video {
 
      margin: 0 auto;
      object-fit: fill;
      width: 100%;
      height: 500px;
    }
  }
}
.product {
  position: relative;
  min-height: 350px;
  // background: red;
  .product_mainBox {
    width: 1296px;
    display: flex;
    margin: 0 auto;

    .product_main {
      position: relative;
      width: 1156px;
      margin: 0 auto;
      overflow: hidden;
      min-height: 300px;
      // display: flex;

      // flex-wrap: wrap;
      #productMain {
        display: flex;
        position: absolute;
        overflow: auto;
        left: 0px;
        .product_main_box {
          position: relative;
          height: 262px;
          width: 207px;
          margin-bottom: 30px;
          user-select: none;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 207px;
            height: 202px;
            display: block;
          }
          div {
            width: 207px;
            height: 60px;
            background: #f0f2f7;
            text-align: center;
            font-size: 16px;
            font-family: Arial;
            font-weight: bold;
            color: #5a5b5e;
            line-height: 60px;
            overflow: hidden;
          }
        }
        .product_main_box:hover {
          span {
            position: absolute;
            width: 167px;
            height: 220px;
            // outline: 1px solid red;
            display: block;
            color: #ffffff;
            padding: 20px;
            font-size: 16px;
            letter-spacing: 2px;
            line-height: 22px;
            background: rgba(26, 52, 112, 0.4);
            left: 0;
            top: 0;
          }
          div {
            color: #ffffff;
            background: #004a9c;
          }
        }
        .product_main_box:not(:nth-last-of-type(1)) {
          margin-right: 30px;
        }
      }
    }
    .product_rightButton,
    .product_leftButton {
      display: flex;
      align-items: center;
      height: 262px;
      width: 40px;
      background: #e9e8e84d;
      cursor: pointer;
      i {
        font-size: 40px;
        font-weight: 700;
        // outline: 1px solid red;
      }
    }

    .product_rightButton:hover,
    .product_leftButton:hover {
      background: #e9e8e8b4;
    }
  }

  .product_title {
    text-align: center;
    height: 53px;
    padding: 30px;
    font-size: 30px;
    font-family: Arial;
    font-weight: bold;
    color: #004a9c;
  }
}

.about {
  .about_box {
    max-height: 586px;
    display: flex;
    min-width: 1156px;
    .about_box_img {
      width: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .about_box_title {
      width: 50%;
      background: #f0f2f7;
      min-height: 400px;
      div:nth-of-type(1) {
        font-size: 30px;
        font-family: Arial;
        font-weight: bold;
        color: #004a9c;
        margin: 0 auto;
        max-width: 604px;
        text-align: center;
        padding: 94px 0 55px;
      }
      div:nth-of-type(2) {
        margin: 0 auto;
        max-width: 604px;
        font-size: 16px;
        font-family: Arial;
        font-weight: 400;
        color: #5a5b5e;
        line-height: 33px;
      }
    }
    .about_box_img2 {
      width: 0;
    }
    .about_box_title2 {
      width: 100%;
      div {
        max-width: 1156px !important;
      }
    }
  }
  .about_box:nth-of-type(2n) {
    flex-direction: row-reverse;
  }
}
</style>

<style scoped>
.el-carousel {
  height: 500px;
}
.el-carousel__item {
  height: 500px;
}

div /deep/ .el-carousel__item img {
  width: 100%;
  height: 500px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

/* 小圆圈 */
div /deep/.el-carousel__button {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 5px;
  opacity: 1;
}
div /deep/.el-carousel__indicator.is-active button {
  background: #0051da;
}

div /deep/.el-carousel__indicators--horizontal {
  bottom: 30px;
  padding: 0;
  margin: 0 4px;
}
</style>
