import axios from 'axios'

if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = 'https://www.orchidte.com/orchidteapi'
}
//在生产环境中的测试 production
if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = 'https://www.orchidte.com/orchidteapi'
}

axios.defaults.timeout = 5000;
//axios.defaults.headers.common['X-User-Token'] = localStorage.getItem("m-token");

axios.interceptors.request.use(
    config => {
        // config.headers.common['X-User-Token'] = localStorage.getItem("m-token");
        config.headers['Content-Type'] = 'application/json'
        return config
    }
)
axios.interceptors.response.use(res => {
    if (res.data.code == 401) {
        localStorage.removeItem('m-token')
        localStorage.removeItem('nickName')
    }
    if (res.code == 401) {
        localStorage.removeItem('m-token')
        localStorage.removeItem('nickName')
    }
    return res
})
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}